

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');


@tailwind base;
@tailwind components;

// Additional styles
@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
@import "additional-styles/toggle-switch.scss";
// @import 'additional-styles/flatpickr.scss';
@import "additional-styles/theme.scss";

@tailwind utilities;



body {
  font-family: 'Roboto', sans-serif !important;
}
table td{
  font-family: 'Roboto', sans-serif !important
}
table th{
  font-family: 'Roboto', sans-serif !important;
}

.mobileifream .mobileview iframe body.sidebar-expanded .main-sidebar-sections {
  display: none !important;
}


// .table {
//   @apply w-full border-collapse;

//   th,
//   td {
//     @apply py-4 px-4 text-left;
//     color: var(--gray-color);
//     background: #fff;
//     font-weight: 400;
//     font-size: 16px;

//     &:not(:last-child) {
//       @apply pr-4;
//     }
//   }

//   th {
//     font-weight: bold;
//     font-size: 18px;
//     color: #fff;
//     background: #AA3361

//     // &:last-child {
//     //   text-align: center;
//     // }
//   }

//   td {
//     @apply border-b;

//     &:last-child {
//       img {
//         height: 33px;
//         width: 33px;
//         background-color: var(--theme-color);
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         border-radius: 10px;
//         box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
//         padding: 5px;
//       }
//     }
//   }
// }

//   input[type="checkbox"] {
//     color: #ff7c43;

//     &:focus {
//       outline: 0;
//       box-shadow: none;
//     }
//   }
/* toggle startr */
.toggle-class {
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    visibility: hidden;

  }

  label {
    font-family: 'Roboto', sans-serif !important;
    cursor: pointer;
    margin-left: 10px;
    text-indent: -9999px;
    width: 50px;
    height: 26px;
    background: #181818;
    display: block;
    border-radius: 100px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 3px;
      left: 4px;
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 90px;
      transition: 0.3s;
    }
  }

  input:checked+label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }

  input:checked+label {
    background: #AA3361;
  }
}

.toggle-class-new {
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    visibility: hidden;
  }

  label {
    cursor: pointer;
    margin-left: 10px;
    text-indent: -9999px;
    width: 60px;
    height: 24px;
    background: #ebdce2;
    display: block;
    border-radius: 100px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 3px;
      left: 4px;
      width: 18px;
      height: 18px;
      background: #AA3361;
      border-radius: 90px;
      transition: 0.3s;
    }
  }

  input:checked+label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }

  input:checked+label {
    background: #ebdce2;
  }
}

/* toggle end */




// image croper style
.img-logo-upload img {
  min-width: 50%;
  max-width: 50%;
  margin: 0 auto 30px;
}

.fixheight {
  max-height:300px !important;
}



.css-1lb4uql-control,.css-12zf3ro-control {
  min-height:56px !important;
}

.css-qbdosj-Input {
 height: 0px;
}

.css-1lb4uql-control,
.css-12zf3ro-control {
  border: 1px solid #181818 !important;
  height: auto !important;
  min-height: 56px !important;
  max-height: auto !important;
  color: #181818 !important;
}
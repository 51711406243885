// Switch element
.form-switch {
    @apply relative select-none;
    width: 40px;

    label {
        @apply block overflow-hidden cursor-pointer h-5 rounded-full;

        > span:first-child {
            @apply absolute block rounded-full;
            width: 16px;
            height: 16px;
            top: 2px;
            left: 2px;
            right: 50%;
            transition: all .15s ease-out;
        }
    }

    input[type="checkbox"] {
    
        &:checked {

            + label {
                background-color:#81a3b8;

                > span:first-child {
                    left: 22px;
                }
            }
        }

        &:disabled {

            + label {
                @apply cursor-not-allowed bg-slate-100 border border-slate-200;

                > span:first-child {
                    @apply bg-slate-400;
                }
            }            
        }
    }
}
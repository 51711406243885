@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');


body {
    font-family: 'Roboto', sans-serif !important;
}


.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {

    &:focus {
        @apply ring-0;
    }
}

.p10 {
    .cke_contents {
        min-height: 700px !important
    }
}

.line-elpsis {

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

}


.loader_container {
    text-align: center;
    background: #ffffffbd;
}

section {
    width: 30%;
    display: inline-block;
    text-align: center;
    min-height: 215px;
    vertical-align: top;
    margin: 1%;
    border-radius: 5px;

}

@media only screen and (max-width: 600px) {
    section {
        min-width: 350px;
    }
}

.loader {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 75px;
    display: inline-block;
    vertical-align: middle;
}

.loader-star {
    position: absolute;
    top: calc(50% - 12px);
}

/*LOADER-1*/

.loader-1 .loader-outter {
    position: absolute;
    border: 4px solid #AA3361;
    border-left-color: transparent;
    border-bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-animation: loader-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
    animation: loader-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
}

.loader-1 .loader-inner {
    position: absolute;
    border: 4px solid #AA3361;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    border-right: 0;
    border-top-color: transparent;
    -webkit-animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
    animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
}

/*LOADER-2*/


@-webkit-keyframes loader-1-outter {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-1-outter {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes loader-1-inner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@keyframes loader-1-inner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

.buttonload {
    background-color: #AA3361;
    /* Green background */
    border: none;
    /* Remove borders */
    color: white;
    /* White text */
    padding: 12px 16px;
    /* Some padding */
    font-size: 16px
        /* Set a font size */
}